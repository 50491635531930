import React ,{Component} from 'react'

// export default ()=>(
//     <div id="acontainer">
//     <div id='picContainer'>
//         <img src={require('../assets/favicon.jpeg')} id="profilepic"/>
//     </div>
//     <div id="aboutme"></div>
//     </div>
// )
export default  ()=><div>Under Construction</div>