import React from 'react';
export default ()=>(
    <div id='social'>
          <a href='mailto:talhamuhammadashraf@gmail.com'>
          <div id="instaDiv">
            <img
              src='https://www.freepnglogos.com/uploads/email-png/mail-message-email-send-image-pixabay-5.png'
              id='mail'
            /></div>
          </a>
          <a target='blank' href='https://www.linkedin.com/in/talha-ashraf/'>
          <div id="instaDiv">
            <img
              src='https://www.freepnglogos.com/uploads/linkedin-basic-round-social-logo-png-13.png'
              id='li'
            />
            </div>
          </a>
          <a target='blank' href='https://www.github.com/talhamuhammadashraf/'>
          <div id="instaDiv">
            <img
              src='http://pngimg.com/uploads/github/github_PNG58.png'
              id='git'
            />
            </div>
          </a>
          <a target='blank' href='https://twitter.com/Talha_Ganyani'>
          <div id="instaDiv">
            <img
              src='https://cdn3.iconfinder.com/data/icons/social-icons-5/607/Twitterbird.png'
              id='twitter'
            /></div>
          </a>
          <a target='blank' href='https://www.facebook.com/talhaashraf1996'>
          <div id="instaDiv">
            <img
              src='https://en.facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png'
              id='fb'
            /></div>
          </a>
          <a target='blank' href='https://www.instagram.com/talha.m.ashraf/'>
            <div id="instaDiv">
            <img
              src='https://www.freepnglogos.com/uploads/instagram-logo-png-transparent-0.png'
              id='insta'
            />
            </div>
          </a>
        </div>
)

// 