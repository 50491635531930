export default [
    {
      img: 'http://www.theriderspod.com.au/images/site/logosmall.png',
      title: "The Rider's Pod",
      android:'https://play.google.com/store/apps/details?id=com.rpodnew',
      ios:'https://apps.apple.com/au/app/the-riders-pod/id1481397556'
    },
    {
      img:
        'https://lh3.googleusercontent.com/Sygg9ymbRG5B3ZMCjYUwI_xHtd1XM6wkFEJ-VSyBSLhb39PovvKYToJ9dYuSifWkJj8=s180-rw',
      title: 'Total Parco Contractor',
      android:'https://play.google.com/store/apps/details?id=com.totalparco',
      ios:'https://apps.apple.com/pk/app/total-parco-contractor/id1474445080'
    },
    {
      img:
        'https://lh3.googleusercontent.com/Sygg9ymbRG5B3ZMCjYUwI_xHtd1XM6wkFEJ-VSyBSLhb39PovvKYToJ9dYuSifWkJj8=s180-rw',
      title: 'Total Parco Engineer',
      android:'https://play.google.com/store/apps/details?id=com.totalparcoengineer',
      ios:"https://apps.apple.com/pk/app/total-parco-engineer/id1474452241"
    },
    {
      img:
        'https://lh3.googleusercontent.com/qZwJLvOZo8AAErllS42qW3W8WEf5NER9vx8IQJCThYe7H_pfURRaL4rOq3O8zICyvXA=s180-rw',
      title: 'TicketTamer',
      android:'https://play.google.com/store/apps/details?id=com.tickettamer'
    },
    {
      img:
      require('../assets/auovs.png'),
      title: 'AUOVS',
      type:"ud"
    },
    {
      img:
      require('../assets/khaadi.png'),
      title: 'Khaadi Internal Tablet App',
      type:"d"
    },
    {
      img:
        'https://lh3.googleusercontent.com/ejxTTNhBsfbpzGDTnT8ISO8rOFZfkVSIgMlgisl_f0g58VHh5yMCD0kKTGpT-uvEWw=s180-rw',
      title: 'Layzee Consumer App',
      android:'https://play.google.com/store/apps/details?id=com.layzee_customer_app',
      ios:'https://apps.apple.com/ae/app/layzee/id1481051466'
    },
    {
      img:
      require('../assets/deals.png'),
      title: 'Deals Everywhere',
      ios:'https://apps.apple.com/us/app/deals-everywhere/id1457858149?ls=1'
    },




  ]